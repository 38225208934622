import React from 'react'
import style from './style.module.scss'

const DeprecationBanner = () => {
  return (
    <div className={style.deprecationBanner}>
      PraetorApp will be deprecated in favor of provider console on{' '}
      <a
        href="https://provider-console.akash.network/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ fontSize: '16px' }}
        className={style.link}
      >
        provider-console.akash.network
      </a>{' '}
      - please go to{' '}
      <a
        href="https://provider-console.akash.network/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ fontSize: '16px' }}
        className={style.link}
      >
        Provider Console
      </a>{' '}
      , connect your provider&apos;s wallet and confirm you can view resources, leases, earnings
      there. If issues, reach out to us on{' '}
      <a
        href="https://discord.com/channels/747885925232672829/1111749376109445180"
        target="_blank"
        rel="noopener noreferrer"
        style={{ fontSize: '16px' }}
        className={style.link}
      >
        Discord
      </a>
    </div>
  )
}

export default DeprecationBanner
